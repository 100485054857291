<template>
  <el-card shadow="never" class="aui-card--fill listForOther">
    <div class="mod-sys__post">
      <el-form
        :inline="true"
        :model="dataForm"
        @keyup.enter.native="getDataList(1)"
      >
        <el-form-item prop="appCode">
          <el-select v-model="dataForm.appCode" placeholder="应用代码">
            <el-option
              v-for="item in codeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button @click="getDataList(1)">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click="resetHandle()">重置</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="itemEditor({}, 'add')"
            >新增</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        v-loading="dataListLoading"
        :data="dataList"
        border
        style="width: 100%"
      >
        <el-table-column
          prop="appCode"
          label="应用代码"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="appDesc"
          label="应用描述"
          header-align="center"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="appKey"
          label="应用key"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="status"
          label="状态"
          header-align="center"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.status == '1' ? '开启' : '关闭' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="notifyUrl"
          label="应用回调地址"
          header-align="center"
          align="center"
        >
          <template slot-scope="scope">
            <a :href="scope.row.notifyUrl" target="_blank">{{
              scope.row.notifyUrl
            }}</a>
          </template>
        </el-table-column>

        <el-table-column
          label="操作"
          fixed="right"
          header-align="center"
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="itemEditor(scope.row, 'editor')"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-size="10"
        :total="total"
        layout="total, prev, pager, next"
        @current-change="pageCurrentChangeHandle"
      >
      </el-pagination>

      <AppCodeUpdate
        ref="model"
        :itemData="itemData"
        @updatTable="updatTable"
      />
    </div>
  </el-card>
</template>

<script>
import AppCodeUpdate from './appCodeUpdate';

const urls = {
  page: '/sysappsupplier/page',
  del: '/sysappsupplier/info',
  getCode: '/sysappsupplier/list', // 获取应用code
};
// appCode: "MIAOINSTECH"
// appDesc: "缮瓷科技"
// appKey: null
// id: "1"
// notifyUrl: "https://miusapitest.miaoinstech.com/api/healthbusiness/testNotify"
export default {
  data() {
    return {
      //头部表单筛选
      dataForm: {
        appCode: '',
      },
      itemData: {},
      dataListLoading: false,
      dataList: [],
      page: 1,
      total: 0,
      codeList: [],
    };
  },
  components: {
    AppCodeUpdate,
  },
  mounted() {
    this.getDataList();
    this.getCodeList();
  },

  methods: {
    async getCodeList() {
      const res = await this.$http.get(urls.getCode);

      const resData = res.data.data || [];
      this.codeList = resData.map((item) => {
        return {
          label: item.appDesc,
          value: item.appCode,
        };
      });
      console.log(this.limitList, 9099);
    },
    //表格获取数据
    async getDataList(type) {
      if (type) this.page = 1;

      let params = {
        limit: 10,
        page: this.page,
        ...this.dataForm,
      };
      this.dataListLoading = true;
      const res = await this.$http.get(urls.page, { params });
      this.dataListLoading = false;
      const data = res.data;

      const { code = 99, msg = '网络异常' } = data;

      if (code != 0) {
        this.$message.error(msg);
        return false;
      }
      this.dataList = data.data.list;
      this.total = data.data.total;
    },
    //重置表单
    resetHandle() {
      //Vue中重置data的数据为初始状态
      Object.assign(this.$data, this.$options.data());
      this.getDataList();
    },
    //单个编辑
    async itemEditor(data, type) {
      if (type == 'del') {
        this.$confirm('此操作将永久删除该, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(async () => {
            const delRes = await this.$http.delete(urls.del, {
              data: [data.id],
            });

            if (delRes.data.code == 0) {
              this.getDataList();
            }
            this.$message({
              type: 'success',
              message: '删除成功!',
            });
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            });
          });

        return;
      }
      this.itemData = data;
      this.$refs.model.dialogVisible = true;
    },
    //页码操作
    pageCurrentChangeHandle(page) {
      this.page = page;
      this.getDataList();
    },

    updatTable() {
      this.resetHandle();
    },
  },
};
</script>
<style lang="scss">
.listForOther {
  .el-form-item__label {
    width: 150px;
  }
}
</style>
